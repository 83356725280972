import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

// order cod
export const getOrdersCOD = (page, pageSize, search, transport) => {
  let pSearch = "search";
  let pTransport = "none"
  if (search) { pSearch = search; }
  if (transport) { pTransport = transport }
  return httpClient.get(
    server.ORDERCOD_URL + `/pending/page/${page}/${pageSize}/${pSearch}/${pTransport}`
  );
};

export const getOrderCODTotal = (transport = null) => {
  let tr = transport ? transport : 'none';
  return httpClient.get(server.ORDERCOD_URL + `/total/${tr}`);
}

export const getOrderCODTotalPending = (transport = null) => {
  let tr = transport ? transport : 'none';
  return httpClient.get(server.ORDERCOD_URL + `/total/pending/${tr}`);
}

export const getOrderCODTotalPaid = (transport = null) => {
  let tr = transport ? transport : 'none';
  return httpClient.get(server.ORDERCOD_URL + `/total/paid/${tr}`);
}

export const updatBulkPaymentCODInfo = (data) => {
  return httpClient.put(server.ORDERCOD_URL + `/updatBulkPaymentCODInfo`, data);
}

