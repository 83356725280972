<template>
  <v-navigation-drawer app permanent>
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img src="@/assets/pony-ls.jpg" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            >hi! {{ $store.getters.username }}</v-list-item-title
          >
          <v-list-item-subtitle
            >Level: {{ $store.getters.level }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>
    <!-- test -->

    <v-list>
      <v-list-group
        v-for="({ title, icon, rout, items }, index) in menus2"
        :key="index"
        :prepend-icon="icon"
        no-action
        color="green darken-2"
        mandatory
        @click="onClickMenu(rout)"
      >
        <!-- ใน vuegify ค่าใน slot หากดูที่ document appendIcon จะเป็น append-icon ในกรณีที่จะเรียกใช้ใน slot ให้เขียนแบบ camelCase คือเขียนติดกัน -->
        <template v-slot:appendIcon>
          <v-icon v-if="!items"></v-icon>
        </template>

        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="title"></v-list-item-title>
          </v-list-item-content>
        </template>
        <!-- sub menu -->
        <v-list-item
          v-for="subItem in items"
          :key="subItem.title"
          @click="onClickMenu(subItem.rout)"
        >
           <v-list-item-icon v-if="subItem.icon" class="mr-1">
              <v-icon size="18">{{ subItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>

        </v-list-item>
      </v-list-group>
    </v-list>

    <!-- end test -->

    <!-- <v-list shaped>
      <v-subheader>MENUS</v-subheader>
      <v-list-item-group
        v-model="selectedMenunav"
        mandatory
        color="green darken-2"
      >
        <v-list-item
          v-for="({ title, icon, rout, level }, index) in menus"
          :key="index"
          link
          @click="onClickMenu(rout)"
          v-if="level.indexOf(user_level) > -1"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list> -->
  </v-navigation-drawer>
</template>

<script>
import { server } from "@/services/constants";
import _ from "lodash";

export default {
  name: "Menu",
  data() {
    return {
      user_level: "",
      selectedMenunav: "",
      menus: [
        // https://cdn.materialdesignicons.com/2.0.46/   icon
        {
          title: "Live order",
          icon: "mdi-chat-plus-outline",
          rout: "/liveorder",
          routename: ["liveorder"],
          level: ["admin",'super', "normal"],
        },
        {
          title: "Add new",
          icon: "mdi-plus",
          rout: "/billing",
          routename: ["billing"],
          level: ["admin",'super', "normal"],
        },
        {
          title: "Order",
          icon: "mdi-flask-outline",
          rout: "/order",
          routename: ["order", "billingedit", "printorder"],
          level: ["admin",'super', "normal"],
          // active: true,
        },
        {
          title: "Tag",
          icon: "mdi-tag",
          rout: "/tag-report",
          routename: ["tagreport", "tagedit", "tagcreate"],
          level: ["admin",'super', "normal"],
        },
        {
          title: "Flash Express",
          icon: "mdi-flash",
          rout: "",
          routename: ["kabooking", "printordertracking"],
          level: ["admin",'super', "normal"],
          items: [
            // {
            //   title: "KA-Booking",
            //   rout: "/ka-booking",
            //   level: ["admin",'super', "normal"],
            // },
            {
              title: "Freight & Cod fee",
              rout: "/upload-tracking-discount-acc",
              level: ["admin",'super', "normal"],
            }, 
            { title: "COD Remittance", rout: "/cod-tracking", level: ["admin",'super'] },
             {
              title: "Update Tracking(เก่า)",
              rout: "/upload-tracking",
              level: ["admin",'super'],
            }, 
          ],
        },
        {
          title: "KERRY",
          icon: "mdi-cube-send",
          rout: "",
          routename: ["kerrytracking"],
          level: ["admin",'super', "normal"],
          items: [
            {
              title: "Update Pickup List",
              rout: "/upload-kerrytracking",
              level: ["admin",'super'],
            },
             {
              title: "Freight & COD fee",
              rout: "/upload-kerryshipcost",
              level: ["admin",'super'],
            },
            {
              title: "COD Remittance",
              rout: "/upload-codkerry",
              level: ["admin",'super'],
            },
            // { title: "COD Remittance", rout: "/dhl-payment", level: ["admin",'super'] },
          ],
        },
        // {
        //   title: "DHL eCom",
        //   icon: "mdi-cube-send",
        //   rout: "/order-scan",
        //   routename: ["orderscan"],
        //   level: ["admin",'super', "normal"],
        //   items: [
        //     {
        //       title: "Scan Barcode",
        //       icon: "mdi-barcode-scan",
        //       rout: "/order-scan",
        //       level: ["admin",'super', "normal"],
        //     },
        //     {
        //       title: "Update ค่าจัดส่ง",
        //       rout: "/upload-dhltracking",
        //       level: ["admin",'super'],
        //     },
        //     // { title: "COD Remittance", rout: "/dhl-payment", level: ["admin",'super'] },
        //   ],
        // },
        
        {
          title: "COD",
          icon: "mdi-credit-card-plus",
          rout: "",
          routename: ["codorder"],
          level: ["admin",'super'],
          items: [
            { title: "List", rout: "/cod-order", level: ["admin",'super'] },
          ],
        },
        {
          title: "Report",
          icon: "flight_takeoff",
          rout: "",
          routename: ["report"],
          level: ["admin",'super'],
          items: [
            {
              title: "Overview",
              icon: "mdi-cloud-outline",
              rout: "/report",
              level: ["admin",'super'],
            },
            {
              title: "Bank",
              icon: "mdi-chart-arc",
              rout: "/bank-report",
              level: ["admin",'super'],
            },
          ],
        },
        {
          title: "Setting",
          icon: "mdi-cog",
          rout: "",
          routename: ["report"],
          level: ["admin",'super'],
          items: [
            {
              title: "Category",
              icon: "mdi-cloud-outline",
              rout: "/category",
              level: ["admin",'super'],
            },
            {
              title: "Product",
              icon: "mdi-cloud-outline",
              rout: "/product",
              level: ["admin",'super'],
            },
            {
              title: "Branch",
              icon: "mdi-cloud-outline",
              rout: "/branch",
              level: ["admin",'super'],
            },
            {
              title: "Bank",
              icon: "mdi-bank",
              rout: "/bank",
              routename: ["bank", "bankedit", "bankcreate"],
              level: ["admin",'super'],
            },
            {
              title: "User",
              icon: "mdi-security",
              rout: "/user",
              level: ["admin",'super'],
            },
          ],
        },
        // {
        //   title: "Category",
        //   icon: "mdi-cloud-outline",
        //   rout: "/category",
        //   routename: ["category", "categorycreate", "categoryedit"],
        //   level: ["admin",'super'],
        // },
        // {
        //   title: "Product",
        //   icon: "mdi-cloud-outline",
        //   rout: "/product",
        //   routename: ["product", "productedit", "productcreate"],
        //   level: ["admin",'super'],
        // },
        // {
        //   title: "Branch",
        //   icon: "mdi-cloud-outline",
        //   rout: "/branch",
        //   routename: ["branch", "branchedit", "branchcreate"],
        //   level: ["admin",'super'],
        // },
        // {
        //   title: "Bank",
        //   icon: "mdi-bank",
        //   rout: "/bank",
        //   routename: ["bank", "bankedit", "bankcreate"],
        //   level: ["admin",'super'],
        // },
        // // { title: "Register", icon: "mdi-account", rout: "/register" },
        // {
        //   title: "User",
        //   icon: "mdi-security",
        //   rout: "/user",
        //   routename: ["user"],
        //   level: ["admin",'super'],
        // },
      ],
    };
  },
  methods: {
    getUserLevel() {
      this.user_level = localStorage.getItem(server.LEVEL);
    },
    onClickMenu(rout) {
      this.$router.push(rout).catch((err) => {}); //การใส่ catch ที่นี้หมายถึงว่า ป้องกันไม่ให้ เวลากด rout เดิมแล้ว error: NavigationDuplicated  เกิดขึ้น
    },
  },
  watch: {
    // watch เป็น section ที่ไว้สำหรับ detect(ตรวจจับ) ว่า มีอะไรเปลี่ยนแปลงเกิดขึ้นบ้าง เมื่อเกิด event ใดๆเกิดขึ้น
    // ในเหตุการนี้ เราใช้ watch ในการ detect   route เมื่อเราคลิก เมนู ก็ให้เมนูทำการ ไฮไลท์เมนูที่เลือก
    // ใน vuejs และ vuetify จะจำค่าได้ว่าเราคลิกที่เมนูอะไร ก็จะไฮไลท์เมนูนั้นให้ แต่ที่เราต้องใช้ watch ช่วยในจังหวะที่มีการสร้างขึ้นมาใหม่ เช่นจากหน้า login เมื่อ login เสร็จ คำสั่ง
    // router.push('/order') จะทำงาน ทำให้เพจมีการ load componet ขึ้นมาใหม่โดยที่ไม่ได้ผ่านการคลิกเมนูโดยตรง vuetify จึงไม่ได้ทำ hilight เมนูให้ ต้องใช้ watch ช่วยในการทำ ไฮไลท์
    // $route(to, from) {
    //   let componame = to.name.toLowerCase();
    //   this.selectedMenunav = this.menus.findIndex((menu, index) => {
    //     if (menu.routename.findIndex((co) => co == componame) > -1) {
    //       console.log("is click: ", componame);
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    //   console.log("selecetd:", this.selectedMenunav);
    // },
  },
  computed: {
    menus2() {
      let newmenu = this.menus.filter((item) => {
        return _.find(item.level, (level) => {
          if (level == this.user_level) {
            // เจอเมนู 
            return true;
          }
        })
      });

      let menu3 = [];
      _.forEach(newmenu, (menu, index) => {
        menu3.push({...menu});
        if (typeof menu.items != 'undefined') {
          // console.log(menu.items)
            menu3[index].items = [];
           _.forEach(menu.items, (item, i) => {
              // console.log(item.level)
              if (_.includes(item.level, this.user_level) == true) {
                menu3[index].items.push({...item});
              } 
            })
        }
      })
      // console.log(menu3)
     return menu3;

    }
  },
  mounted() {
    this.getUserLevel();
    // let componame = this.$route.name.toLowerCase();
    // let cc = this.menus.findIndex((menu, index) => {
    //   if (menu.routename.findIndex((co) => co == componame) > -1) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    // this.selectedMenunav = cc;
  },
};
</script>
