import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

// product
export const getTag = (page, pageSize, search) => {
  try {
    return httpClient.get(
      server.TAG_URL + `/page/${page}/${pageSize}/${search}`
    );
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const getTagAll = () => {
  try {
    return httpClient.get(server.TAG_URL + `/all`);
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const getTagReport = () => {
  return httpClient.get(server.TAG_URL + `/report`);
}

export const getTagById = (id) => {
  return httpClient.get(server.TAG_URL + `/id/${id}`);
};

export const getTagReportById = (id, page, pageSize, search) => {
  return httpClient.get(
    server.TAG_URL + `/report/id/${id}/${page}/${pageSize}/${search}`
  );
};

export const addTag = (data) => {
  return httpClient.post(server.TAG_URL, data);
};

export const deleteTag = (id) => {
  return httpClient.delete(server.TAG_URL + `/id/${id}`);
};

export const updateTag = (data) => {
  return httpClient.put(server.TAG_URL, data);
};

export const getTagByOrderId = (id) => {
  return httpClient.get(server.TAG_URL + `/order/id/${id}`);
};
