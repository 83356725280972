<template>
  <v-main class="v-main-for-print grey lighten-3" >
    <router-view></router-view>
  </v-main>
</template>

<script>
export default {
  name: "Content",
};
</script>

<style>
/* แก้ hilight ของ autocomplete */
.theme--light.v-list-item .v-list-item__mask {
  color: #000;
  background-color: #fffc57bf;
}

.centered-input input {
  text-align: center;
}
</style>

<style scoped>
@media print {
  .v-main-for-print {
    padding-top: 0 !important;
  }
}
</style>
