import httpClient from "@/services/httpClient";
import { server, ORDERSTATUS, PAYMENTMETHOD, ORDERID_PREFIX,DHL_PREFIX,KERRY_PREFIX,BANKLIMIT,SCANSTATUS,LIVEORDERSTATUS,TRANSPORTER,CODBANK,CODSTATUS } from "@/services/constants";
import router from "../router"; // หรือ form "@/router" ก็คือ folder router นั้นเอง
import * as productApis from "@/services/api_product";
import * as branchApis from "@/services/api_branch";
import * as userApis from "@/services/api_user";
import * as orderApis from "@/services/api_order";
import * as reportApis from "@/services/api_report";
import * as trackingApis from "@/services/api_tracking";
import * as ordercodApis from "@/services/api_ordercod";
import * as bankApis from "@/services/api_bank";
import * as tagApis from "@/services/api_tag";
import * as orderScanApis from "@/services/api_orderscan";
import * as liveOrderApis from "@/services/api_liveorder";

const isLoggedIn = () => {
  let token = localStorage.getItem(server.TOKEN_KEY);
  return token != null;
};

const login = async values => {
  try {
    let result = await httpClient.post(server.LOGIN_URL, values);
    if (result.data.result == "ok") {

      localStorage.setItem(server.USERNAME, values.username);
      localStorage.setItem(server.TOKEN_KEY, result.data.token);
      localStorage.setItem(server.USERID, result.data.message.id);
      localStorage.setItem(server.LEVEL, result.data.message.level);
      router.push("/order");
      return { result: "ok", message: "เข้าสู่ระบบสำเร็จ", userid: result.data.message.id, level: result.data.message.level };
    } else {
      return { result: "nok", message: result.data.message };
    }
  } catch (error) {
    return { result: "nok", message: error.message };
  }
};

const register = async values => {
  let result = await httpClient.post(server.REGISTER_URL, values);
  if (result.data.result == "ok") {
    // router.go(-1);
    // router.push("/login");
    return result.data;
  } else {
    // console.log(result.data)
    return result.data;
  }
};

const logout = () => {
  localStorage.removeItem(server.TOKEN_KEY);
  router.push("/login");
};

export default {
  isLoggedIn,
  login,
  logout,
  register,
  ...productApis,
  ...branchApis,
  ...userApis,
  ...orderApis,
  ...reportApis,
  ...trackingApis,
  ...ordercodApis,
  ...bankApis,
  ...tagApis,
  ...orderScanApis,
  ...liveOrderApis
};

export { ORDERSTATUS, PAYMENTMETHOD, ORDERID_PREFIX, DHL_PREFIX, KERRY_PREFIX, BANKLIMIT, SCANSTATUS, LIVEORDERSTATUS, TRANSPORTER, CODBANK,CODSTATUS};
