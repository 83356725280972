<template>
  <v-app>
   <Header v-if="$store.getters['isLogin']" class="d-print-none"/>
   <Menu v-if="$store.getters['isShowMenu']" class="d-print-none"/>
   <Content/>
  </v-app>
</template>

<script>

import Header from "@/components/core/Header"
import Menu from "@/components/core/Menu"
import Content from "@/components/core/Content"


export default {
  name: "myApp",
  components: {
    Header,
    Menu,
    Content
  },

  mounted() { // mounted จะทำงานก่อนที่ component กำลังโหลดขึ้นมา
    this.$store.dispatch({type: "restoreLogin"})
  },
};
</script>
