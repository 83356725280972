<template>
    <v-app-bar app color="green darken-2" dense dark flat hide-on-scroll >
      <v-app-bar-nav-icon @click="onClickToggleMenu()"></v-app-bar-nav-icon>
      <v-toolbar-title><span class="subtitle-1">{{ project_name }} V.{{ version }}</span></v-toolbar-title>
      <v-spacer></v-spacer>
      <span>Log out</span>
      <v-btn icon @click="onClickLogOff">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
    }
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    project_name() {
      return process.env.VUE_APP_TITLE;
    }
  },
  methods: {
    onClickLogOff() {
      // this.$router.push("/login")
      this.$store.dispatch({
        type: "doLogout"
      });
    },
    onClickToggleMenu() {
      this.$store.dispatch({
        type: "doShowMenu"
      });
    }
  }
};
</script>

<style></style>
