import { imageUrl } from "./constants";
import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";


// branch
export const addBranch = data => {
    return httpClient.post(server.BRANCH_URL, data);
  }
  
  export const getBranches = (page,pageSize,search) => {
    try {
      return httpClient.get(server.BRANCH_URL + `/page/${page}/${pageSize}/${search}`);
    } catch (error) {
      console.log(error.message);
      return null;
    }
  };
  
  export const deleteBranch = id => {
    return httpClient.delete(server.BRANCH_URL + `/id/${id}`);
  }
  
  export const getBranchById = id => {
    return httpClient.get(server.BRANCH_URL + `/id/${id}`);
  };
  
  export const updateBranch = data => {
    return httpClient.put(server.BRANCH_URL, data);
  };
  
  export const updateBranchToActive = data => {
    return httpClient.put(server.BRANCH_URL + `/setactive`, data);
  }
  
  export const getBranchActive = () => {
    return httpClient.get(server.BRANCH_URL + `/active`);
  }
  