import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";


// order
export const getReport = (durations) => {  
  return httpClient.get(server.REPORT_URL + `/${durations}`);
};

export const getReportByBranch = (id, durations) => {  
  return httpClient.get(server.REPORT_URL + `/branch/id/${id}/${durations}`);
};
