import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

// product
export const getUsers = (page,pageSize,search) => {
  try {
    // const token_user = localStorage.getItem(server.TOKEN_KEY);
    return httpClient.get(server.USER_URL + `/page/${page}/${pageSize}/${search}`);
  } catch (error) {
    console.log(error.message);
    return null;
  }
  
};

export const getUserById = id => {
  return httpClient.get(server.USER_URL + `/id/${id}`);
};

export const addUser = data => {
  return httpClient.post(server.USER_URL, data);
};

export const deleteUser = id => {
  return httpClient.delete(server.USER_URL + `/id/${id}`);
};

export const updateUser = data => {
  return httpClient.put(server.USER_URL, data);
};

export const updateUserToActive = data => {
  //console.log(data)
  return httpClient.put(server.USER_URL + `/setactive`, data);
}

export const updateUserToLevel = data => {
  return httpClient.put(server.USER_URL + `/setlevel`, data);
}

export const checkValidUserToken = () => {
  // const token_user = localStorage.getItem(server.TOKEN_KEY);
  // return httpClient.get(server.USER_URL + `/checkvalidtoken/token/${token_user}`);
  return httpClient.get(server.USER_URL + `/checkvalidtoken/token`);
}