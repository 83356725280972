import { imageUrl } from "./constants";
import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

// export const getProducts = () => {
//   return httpClient.get(server.PRODUCT_URL);
// };

// product
export const getProducts = (page,pageSize,search) => {
  try {
    return httpClient.get(server.PRODUCT_URL + `/page/${page}/${pageSize}/${search}`);
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const getProductByCategoryId = id => {
  return httpClient.get(server.PRODUCT_URL + `/category/id/${id}`);
};

export const getProductById = id => {
  return httpClient.get(server.PRODUCT_URL + `/id/${id}`);
};

export const addProduct = data => {
  return httpClient.post(server.PRODUCT_URL, data);
};

export const deleteProduct = id => {
  return httpClient.delete(server.PRODUCT_URL + `/id/${id}`);
};

export const updateProduct = data => {
  return httpClient.put(server.PRODUCT_URL, data);
};

export const updateProductToActive = data => {
  return httpClient.put(server.PRODUCT_URL + `/setactive`, data);
}

// category
export const addCategory = data => {
  return httpClient.post(server.CATEGORY_URL, data);
}

export const getCategories = (page,pageSize,search) => {
  try {
    return httpClient.get(server.CATEGORY_URL + `/page/${page}/${pageSize}/${search}`);
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const deleteCategory = id => {
  return httpClient.delete(server.CATEGORY_URL + `/id/${id}`);
}

export const getCategoryById = id => {
  return httpClient.get(server.CATEGORY_URL + `/id/${id}`);
};

export const updateCategory = data => {
  return httpClient.put(server.CATEGORY_URL, data);
};

export const updateCategoryToActive = data => {
  return httpClient.put(server.CATEGORY_URL + `/setactive`, data);
}

export const getCategoryActive = () => {
  return httpClient.get(server.CATEGORY_URL + `/active`);
}
