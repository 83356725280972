import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

// order cod
export const getOrdersScan = (page, pageSize, search, status = null) => {
  if (search == "") search = "none";
  let statusCode = status != "none" ? `/${status}` : "/none";
  return httpClient.get(
    server.ORDERSCAN_URL +
      `/all/page/${page}/${pageSize}/${search}${statusCode}`
  );
};

export const updateBulkOrderScanStatus = (data) => {
  return httpClient.put(server.ORDERSCAN_URL + `/setBulkOrderScanStatus`, data);
};

export const updateOrderScanQty = (data) => {
  return httpClient.put(server.ORDERSCAN_URL + `/updateOrderScanQty`, data);
};

export const setBulkOrderScanUpdateTrack = (data) => {
  return httpClient.put(server.ORDERSCAN_URL + `/setBulkOrderScanUpdateTrack`, data);
};

export const insertToOrderScan = (data) => {
  return httpClient.post(server.ORDERSCAN_URL + `/insertToOrderScan`, data);
};

export const deleteBulkOrderScan = (data) => {
  return httpClient.post(server.ORDERSCAN_URL + `/deleteBulkOrderScan`, data);
};

export const deleteOrderScan = (id) => {
  return httpClient.delete(server.ORDERSCAN_URL + `/id/${id}`);
};

export const getTotalOrderScanPending = () => {
  return httpClient.get(server.ORDERSCAN_URL + `/total/pending`);
};

