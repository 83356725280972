import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

// bank
export const addBank = data => {
  return httpClient.post(server.BANK_URL, data);
};

export const getBanks = (page, pageSize, search) => {
  try {
    return httpClient.get(
      server.BANK_URL + `/page/${page}/${pageSize}/${search}`
    );
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const deleteBank = id => {
  return httpClient.delete(server.BANK_URL + `/id/${id}`);
};

export const getBankById = id => {
  return httpClient.get(server.BANK_URL + `/id/${id}`);
};

export const updateBank = data => {
  return httpClient.put(server.BANK_URL, data);
};

export const updateBankToActive = data => {
  return httpClient.put(server.BANK_URL + `/setactive`, data);
};

export const getBankActive = () => {
  return httpClient.get(server.BANK_URL + `/active`);
};

export const getBankAll = () => {
  return httpClient.get(server.BANK_URL + `/all`);
};

export const getBankReportTransfer = (period = null) => {
  let p_period = period || 'none';
  return httpClient.get(server.BANK_URL + `/report/transfer/${p_period}`);
};

export const getBankReportCOD = (period = null) => {
  let p_period = period || 'none';
  return httpClient.get(server.BANK_URL + `/report/cod/${p_period}`);
};