//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE =
  "An error has occurred. The photo was unable to upload.";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

export const server = {
  LOGIN_URL: `login`,
  REGISTER_URL: `register`,
  PRODUCT_URL: `product`,
  BRANCH_URL: `branch`,
  CATEGORY_URL: `category`,
  TRANSACTION_URL: `transaction`,
  REPORT_URL: `report`,
  TOKEN_KEY: `token`,
  USERNAME: `username`,
  USERID: `userid`,
  LEVEL: `level`,
  USER_URL: `user`,
  ORDER_URL: `order`,
  REPORT_URL: `report`,
  TRACKING_URL: `tracking`,
  ORDERCOD_URL: `ordercod`,
  BANK_URL: `bank`,
  TAG_URL: `tag`,
  ORDERSCAN_URL: `orderscan`,
  LIVEORDER_URL: `liveorder`,
};

export const ORDERID_PREFIX = process.env.VUE_APP_ORDERPREFIX;
export const DHL_PREFIX = process.env.VUE_APP_DHLPREFIX;
export const KERRY_PREFIX = process.env.VUE_APP_KERRYPREFIX;

export const CODBANK = {
  FLASH: process.env.VUE_APP_FLASHCODBANK,
  KERRY: process.env.VUE_APP_KERRYCODBANK
}

export const ORDERSTATUS = {
  WAIT: 1,
  PRINTED: 2,
  COMPLETED: 3,
  PENDING: 4,
  CANCELLED: 5,
  PREORDER: 6,
  SHIPPING: 7,
  BOOKING: 8,
  KAEXPORTED: 9,
  KAPRINTED: 10,
  KERRYEXPORTED: 11,
  KERRYBOOKING: 12,
  KERRYPRINTED: 13,
};

export const PAYMENTMETHOD = {
  TRANFER: 1,
  COD: 2,
};

export const CODSTATUS = {
  PENDING: 0,
  PAID: 1,
};

export const BANKLIMIT = {
  TRANSFERAMOUNT: 1800000,
  TRANSFERCOUNT: 2700,
  CODCOUNT: 370,
};

export const SCANSTATUS = {
  PENDING: 1,
  BOOKING: 2,
};

export const LIVEORDERSTATUS = {
  HIDDEN: 1,
  COMPLETED: 2,
  PENDING: 3,
};

export const TRANSPORTER = {
    FLASHEXPRESS: 1,
    KERRYEXPRESS: 2
}

// export const IMAGEURL = "http://localhost:8081/images";
// export const APIURL = "http://localhost:8081/api/v2";

export const IMAGEURL = process.env.VUE_APP_NODE_URL + "/images";
export const APIURL = process.env.VUE_APP_NODE_URL + "/api/v2";

// export const IMAGEURL = "https://www.colligiofficial.com/images";
// export const APIURL = "https://www.colligiofficial.com/api/v2";
