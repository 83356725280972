import Vue from "vue";
import VueRouter from "vue-router";
// เครือ่งหมาย @ ที่ from ตรง path หมายถึง การอ้างอิงตำแหน่งเริ่มต้นของ path ที่เป็น public ใน vue ตำแหน่งเร่ิมต้นจะอยู่ที่ folder src
// เรียนการ import file แบบนี้ว่า import แบบ function เป็นการทำ lazyload componet สำหรับ vuejs แต่ ถ้า import แบบธรรมดาจะเป็น import api from "@/services/api"; ลักษณะนี้ืย
const Login = () => import("@/views/Login.vue"/* webpackChunkName: 'login' */); // --> /* webpackChunkName: 'login' */ เป็นการตั้งชื่อไฟล์ ที่ complie ออกมาของ webpack ปกติจะเป็น 0.js 1.js มั่วๆออกมา
const Register = () => import("@/views/Register.vue");
const CategoryCreate = () => import("@/views/CategoryCreate.vue"/* webpackChunkName: 'categorycreate' */);
const CategoryEdit = () => import("@/views/CategoryEdit.vue"/* webpackChunkName: 'categoryedit' */);
const Category = () => import("@/views/Category.vue");
const ProductCreate = () => import("@/views/ProductCreate.vue");
const ProductEdit = () => import("@/views/ProductEdit.vue");
const Product = () => import("@/views/Product.vue");
const BranchCreate = () => import("@/views/BranchCreate.vue");
const BranchEdit = () => import("@/views/BranchEdit.vue");
const Branch = () => import("@/views/Branch.vue");
const User = () => import("@/views/User.vue");
const UserEdit = () => import("@/views/UserEdit.vue");
const Billing = () => import("@/views/Billing.vue");
const BillingEdit = () => import("@/views/BillingEdit.vue");
const Order = () => import("@/views/Order.vue");
const PrintOrder = () => import("@/views/PrintOrder.vue");
const Report = () => import("@/views/Report.vue");
const BankCreate = () => import("@/views/BankCreate.vue");
const BankEdit = () => import("@/views/BankEdit.vue");
const Bank = () => import("@/views/Bank.vue");
const BankReport = () => import("@/views/BankReport.vue");
const TagReport = () => import("@/views/TagReport.vue");
const TagCreate = () => import("@/views/TagCreate.vue");
const TagEdit = () => import("@/views/TagEdit.vue");
const OrderLive = () => import("@/views/OrderLive.vue");

const UploadTrackingKerryAcc = () => import("@/views/UploadTrackingKerryAcc.vue");
const UploadShipCostKerryAcc = () => import("@/views/UploadShipCostKerryAcc.vue");
const UploadCodKerryAcc = () => import("@/views/UploadCodKerryAcc.vue");

const UploadTracking = () => import("@/views/UploadTracking.vue");
const UploadTrackingDiscountAcc = () => import("@/views/UploadTrackingDiscountAcc.vue");
// const UploadDHLTracking = () => import("@/views/UploadDHLTracking.vue");
//const DHLPayment = () => import("@/views/dhlPayment.vue");
// const KABooking = () => import("@/views/KABooking.vue");
// const OrderScan = () => import("@/views/OrderScan.vue");
const PrintOrderTracking = () => import("@/views/PrintOrderTracking.vue");
const PrintOrderKerryTracking = () => import("@/views/PrintOrderKerryTracking.vue");
const CodTracking = () => import("@/views/CodTracking.vue");
const CodOrder = () => import("@/views/CodOrder.vue");
const Logout = () => import("@/views/Logout.vue");

import api from "@/services/api";
import {server} from "@/services/constants";


Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/category",
    name: "Category",
    meta: { isSecured: true, level: ['admin','super'] },
    component: Category
  },
  {
    path: "/category-create",
    name: "CategoryCreate",
    meta: { isSecured: true, level: ['admin','super'] },
    component: CategoryCreate
  },
  {
    path: "/category-edit/:id",
    name: "categoryEdit",
    meta: { isSecured: true,  level: ['admin','super'] },
    component: CategoryEdit
  },
  {
    path: "/product-create",
    name: "productCreate",
    meta: { isSecured: true,  level: ['admin','super'] },
    component: ProductCreate
  },
  {
    path: "/product",
    name: "Product",
    meta: { isSecured: true, level: ['admin','super'] },
    component: Product
  },
  {
    path: "/product-edit/:id",
    name: "ProductEdit",
    meta: { isSecured: true, level: ['admin','super'] },
    component: ProductEdit
  },
  {
    path: "/branch-create",
    name: "BranchCreate",
    meta: { isSecured: true, level: ['admin','super'] },
    component: BranchCreate
  },
  {
    path: "/branch-edit/:id",
    name: "BranchEdit",
    meta: { isSecured: true, level: ['admin','super'] },
    component: BranchEdit
  },
  {
    path: "/branch",
    name: "Branch",
    meta: { isSecured: true, level: ['admin','super'] },
    component: Branch
  },
  {
    path: "/bank-create",
    name: "BankCreate",
    meta: { isSecured: true, level: ['admin','super'] },
    component: BankCreate
  },
  {
    path: "/bank-edit/:id",
    name: "BankEdit",
    meta: { isSecured: true, level: ['admin','super'] },
    component: BankEdit
  },
  {
    path: "/bank",
    name: "Bank",
    meta: { isSecured: true, level: ['admin','super'] },
    component: Bank
  },
  {
    path: "/bank-report",
    name: "BankReport",
    meta: { isSecured: true, level: ['admin','super'] },
    component: BankReport
  },
  {
    path: "/user", //root
    name: "User",
    meta: { isSecured: true,  level: ['admin','super'] },
    component: User
  },
  {
    path: "/user-edit/:id", //root
    name: "UserEdit",
    meta: { isSecured: true, level: ['admin','super'] },
    component: UserEdit
  },
  {
    path: "/billing", //root
    name: "Billing",
    meta: { isSecured: true, level: ['normal', 'admin', 'super'] },
    component: Billing
  },
  {
    path: "/billing-edit/:id", //root
    name: "BillingEdit",
    meta: { isSecured: true, level: ['normal', 'admin', 'super'] },
    component: BillingEdit
  },
  {
    path: "/order/", //root
    name: "Order",
    meta: { isSecured: true, level: ['normal', 'admin', 'super'] },
    component: Order
  },
  {
    path: "/printorder/filter/:filter/timestamp/:timestamp", //root
    name: "PrintOrder",
    meta: { isSecured: true, level: ['normal', 'admin', 'super'] },
    component: PrintOrder
  },
  {
    path: "/report/", //root
    name: "Report",
    meta: { isSecured: true, level: ['admin', 'super'] },
    component: Report
  },
  // {
  //   path: "/ka-booking", //root
  //   name: "KABooking",
  //   meta: { isSecured: true, level: ['normal', 'admin'] },
  //   component: KABooking
  // },
  {
    path: "/print-order-tracking/timestamp/:timestamp", //root
    name: "PrintOrderTracking",
    meta: { isSecured: true, level: ['normal', 'admin','super'] },
    component: PrintOrderTracking
  },  
  {
    path: "/print-order-kerry-tracking/timestamp/:timestamp", //root
    name: "PrintOrderKerryTracking",
    meta: { isSecured: true, level: ['normal', 'admin','super'] },
    component: PrintOrderKerryTracking
  },
  {
    path: "/upload-tracking-discount-acc/", //root
    name: "UploadTrackingDiscountAcc",
    meta: { isSecured: true, level: ['normal', 'admin','super'] },
    component: UploadTrackingDiscountAcc
  },
  {
    path: "/upload-tracking/", //root
    name: "UploadTracking",
    meta: { isSecured: true, level: ['normal', 'admin','super'] },
    component: UploadTracking
  },
  // {
  //   path: "/upload-dhltracking", //root
  //   name: "UploadDHLTracking",
  //   meta: { isSecured: true, level: ['admin'] },
  //   component: UploadDHLTracking
  // },
  // {
  //   path: "/dhl-payment", //root
  //   name: "DHLPayment",
  //   meta: { isSecured: true, level: ['admin'] },
  //   component: DHLPayment
  // },
  {
    path: "/cod-tracking/", //root
    name: "CodTracking",
    meta: { isSecured: true, level: ['admin','super'] },
    component: CodTracking
  },
  {
    path: "/upload-kerrytracking/", //root
    name: "UploadTrackingKerryAcc",
    meta: { isSecured: true, level: ['admin','super'] },
    component: UploadTrackingKerryAcc
  },
  {
    path: "/upload-kerryshipcost/", //root
    name: "UploadShipCostKerryAcc",
    meta: { isSecured: true, level: ['admin','super'] },
    component: UploadShipCostKerryAcc
  },
  {
    path: "/upload-codkerry/", //root
    name: "UploadCodKerryAcc",
    meta: { isSecured: true, level: ['admin','super'] },
    component: UploadCodKerryAcc
  },
  {
    path: "/cod-order/", //root
    name: "CodOrder",
    meta: { isSecured: true, level: ['admin','super'] },
    component: CodOrder
  },
  {
    path: "/tag-create/", //root
    name: "TagCreate",
    meta: { isSecured: true, level: ['admin', 'normal'] },
    component: TagCreate
  },
  {
    path: "/tag-edit/:id", //root
    name: "TagEdit",
    meta: { isSecured: true, level: ['admin','super','normal'] },
    component: TagEdit
  },
  {
    path: "/tag-report", //root
    name: "TagReport",
    meta: { isSecured: true, level: ['admin','super','normal'] },
    component: TagReport
  },
  // {
  //   path: "/order-scan", //root
  //   name: "OrderScan",
  //   meta: { isSecured: true, level: ['admin', 'normal'] },
  //   component: OrderScan
  // },
  {
    path: "/liveorder",
    name: "LiveOrder",
    meta: { isSecured: true, level: ['admin','super','normal'] },
    component: OrderLive
  },
  {
    path: "/logout/", //root
    name: "Logout",
    component: Logout
  },
  {
    path: "/", //root
    redirect: "/login"
    
  },
  {
    path: "*", // page not found   เครื่องหมาย *  หมายถึง อะไรก็ตาม ให้ไปที่หน้า login แทน
    rediret: "/login"
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue") // การ import ลักษณะนี้เรียกว่า lazy-loaded ซึ่งจะโหลดเฉพาะตอนเรียกใช้งานเท่านั้น  ข้อดี ทำให้เปิดหน้าเว็บเร็ว ข้อเสีย จะมีจังหวะหน่วงตอนเปลี่ยนหน้า
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) { //เวลาเปลี่ยน component จะย้าย scroll ไปที่ด้านบนสุดของ เพจ  เรียกวิธีการนี้ว่า scrollBehavior
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

// Router guard
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => (record.meta.isSecured ? true : false))) {
    // secure route
    
    if (api.isLoggedIn()) {

      // check token is valid
      let result = await api.checkValidUserToken();
      let isValid = result.data.result == 'ok' ? true:false;
      console.log('token is:', isValid)
      if (isValid) {
           // check permission to access menu
            let level = localStorage.getItem(server.LEVEL);
            if (to.meta.level.indexOf(level) > -1) {
                next();
            } else {
              next('/order');
            }
      } else {
        console.log('do logout in rounter')
        next('/logout');
      }
   
      //  if (to.meta.level == level) {
      //      next();
      //  } else {
      //    if (to.meta.level == 'normal') {
      //       next();
      //    } else {
      //       next("/login");
      //    }
      //  }
      
    } else {
      next("/login");
    }
  } else {
    // unsecure route
    next();
  }
});

export default router;
