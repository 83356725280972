import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vue2Filters from "vue2-filters";
import VueClipboard from "vue-clipboard2";
import { IMAGEURL, ORDERID_PREFIX, DHL_PREFIX, KERRY_PREFIX } from '@/services/constants';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

Vue.use(VueClipboard);
Vue.use(Vue2Filters);
Vue.use(require('vue-moment'));

// สร้าง filter ขึ้นมาใช้เอง หรือ จะใช้ของ Vue2Filter ก็ได้ ตัวอย่างการใช้งาน {{ item.image | imageUrl }}
Vue.filter("imageUrl", function(image) { 
  //return `${IMAGEURL}/${image}?timestamp=${Date.now()}`; //กรณีภาพติด cache
  return `${IMAGEURL}/${image}`;
});

Vue.filter("orderIdFormat", function(id) {
  return ORDERID_PREFIX + _.padStart(id, "5", "0");
});

Vue.filter("KERRYTrackingFormat", function(id) {
  return KERRY_PREFIX+''+ORDERID_PREFIX + _.padStart(id, "5", "0");
});

Vue.filter("DHLTrackingFormat", function(id) {
  return DHL_PREFIX + ORDERID_PREFIX + _.padStart(id, "5", "0");
});