import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getOrderByIdAndTel = (id, tel) => {
  return httpClient.get(server.TRACKING_URL + `/order/${id}/${tel}`);
};

export const getOrdersByIds = (ids) => {
  return httpClient.post(server.TRACKING_URL + `/find-orders-by-ids`, ids);
};

export const getOrderById = (id, tel) => {
  return httpClient.get(server.TRACKING_URL + `/order/${id}`);
};

export const getOrderByDHLTracking = (tracking) => {
  return httpClient.get(server.TRACKING_URL + `/order/dhl/tracking/${tracking}`);
};

export const updateTrackingOrderByIdAndTel = data => {
  return httpClient.put(server.TRACKING_URL + `/order`, data);
};

export const updateTrackingOrderByTrack = data => {
  return httpClient.put(server.TRACKING_URL + `/update-shipping-cost-by-track`, data);
};

export const updatePickupListKerry = data => {
  return httpClient.put(server.TRACKING_URL + `/update-pickuplist-kerry`, data);
};

export const updateFreightByTrack = data => {
  return httpClient.put(server.TRACKING_URL + `/update-freight-by-track`, data);
};

export const updateCodfeeByTrack = data => {
  return httpClient.put(server.TRACKING_URL + `/update-codfee-by-track`, data);
};

export const updateCostDHlShipment = data => {
  return httpClient.put(server.TRACKING_URL + `/order/costupdatedhl`, data);
};

export const getOrderByTrackingCODRemittanceFlash = data => {
  // return httpClient.get(server.TRACKING_URL + `/findcod/${tracking}`);
  return httpClient.post(server.TRACKING_URL + `/find-orders-cod-flash`, data);
};

export const updateCODOrderByTracking = data => {
  return httpClient.put(server.TRACKING_URL + `/order/cod`, data);
};

export const getOrderByTracks = data => {
  return httpClient.post(server.TRACKING_URL + `/find-orders-by-tracks`, data);
};

export const updateCodRemittanceKerry = data => {
  return httpClient.put(server.TRACKING_URL + `/update-codremittance-kerry`, data);
};

export const updateCodRemittanceFlash = data => {
  return httpClient.put(server.TRACKING_URL + `/update-codremittance-flash`, data);
};

// export const getOrderByIdAndTracking = (id, tracking) => {
//   return httpClient.get(server.TRACKING_URL + `/order/cod/${id}/${tracking}`);
// };

// export const getOrderByFindTrack = (track) => {
//   return httpClient.get(server.TRACKING_URL + `/find-order-track/${track}`);
// };