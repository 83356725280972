import Vue from "vue";
import Vuex from "vuex";
import api from "@/services/api";
import {server} from "@/services/constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogged: false,
    username: "",
    userid: "",
    level: "", //ระดับการเข้าถึงเมนู ตาม สิทธิ์ user
    loginStatusMessage: "",
    isShowMenu: false,
    itemsSelected: [],
    kaBooking: [],
  },
  getters: {
    isLogin(state) {
      return state.isLogged;
    }, 
    username(state) {
      return state.username;
    },
    userid(state) {
      return state.userid;
    },
    loginStatusMessage(state) {
      return state.loginStatusMessage;
    },
    isShowMenu(state) {
      return state.isShowMenu;
    },
    itemsSelected(state) {
      return state.itemsSelected;
    },
    level(state) {
      return state.level;
    },
    kaBooking(state) {
      return state.kaBooking;
    }
  },
  mutations: {
    SET_LOGGED_IN(state) {
      state.isLogged = true;
      state.loginStatusMessage = "เข้าสู่ระบบสำเร็จ กรุณารอสักครู่..."
    },
    SET_LOGGED_MESSAGE(state, value) {
      state.loginStatusMessage = value;
    },
    SET_LOGGED_OUT(state) {
      state.isLogged = false;
    },
    SET_USERNAME(state, value) {
      state.username = value;
    },
    SET_USERID(state, value) {
      state.userid = value;
    },
    SET_LEVEL(state, value) {
      state.level = value;
    },
    SET_IS_SHOWMENU(state, value) {
      state.isShowMenu = value;
    },
    SET_ITEMS_SELECTED(state, value) {
      state.itemsSelected = value;
    },
    SET_KA_BOOKING(state, value) {
      state.kaBooking = value;
    }
  },
  actions: {
    async doLogin({commit, dispatch}, {username, password}) {
      let result = await api.login({username, password});
      if (result.result == "ok") {
          commit("SET_LOGGED_IN"); // ไปเรียก mutations ให้ทำงาน
          commit("SET_USERNAME", username); // ไปเรียก mutations ให้ทำงาน
          commit("SET_IS_SHOWMENU", true);
          commit("SET_USERID", result.userid);
          commit("SET_LEVEL", result.level);
      } else {
          commit("SET_LOGGED_MESSAGE", result.message);
        // this.dispatch("doLogout", {}); เรียก action doLogout ให้ทำงาน
      }
    },
    doLogout({commit}) {
      api.logout();
      commit("SET_LOGGED_OUT"); // ไปเรียก mutations ให้ทำงาน
      commit("SET_USERNAME", ""); // ไปเรียก mutations ให้ทำงาน
      commit("SET_USERID", "");
      commit("SET_IS_SHOWMENU", false);
      commit("SET_LEVEL", "");
      this.dispatch("clearLoginMessage");
    },
    restoreLogin({commit}) {
      if (api.isLoggedIn() == true) {
        let username = localStorage.getItem(server.USERNAME);
        let userid = localStorage.getItem(server.USERID);
        let level = localStorage.getItem(server.LEVEL);
        commit("SET_LOGGED_IN"); // ใช้ คำสั่ง commit เพื่อเรียก mutations ให้ทำงาน
        commit("SET_USERNAME", username);
        commit("SET_USERID", userid);
        commit("SET_LEVEL", level);
        // console.log("state", this.state.isShowMenu);
      }
    },
    clearLoginMessage({commit}) {
      commit("SET_LOGGED_MESSAGE", "");
    },
    doShowMenu({commit}) {
      commit("SET_IS_SHOWMENU", !this.state.isShowMenu); //เครื่องหมาย ! หน้า true หรือ false เมื่อใส่หน้า จะเป็นการ toggle ระหว่าง true -> false, false -> true
    },
    hideMenu({commit}, {isHide}) {
      commit("SET_IS_SHOWMENU", isHide);
    },
    selectItems({commit}, {items}) {
      commit("SET_ITEMS_SELECTED", items);
    },
    saveKaBooking({commit}, {items}) {
      commit("SET_KA_BOOKING", items);
    }
  },
  modules: {}
});
