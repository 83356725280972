import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

// live order
export const addLiveOrder = data => {
  return httpClient.post(server.LIVEORDER_URL, data);
};

export const getLiveOrders = () => {
  try {
    return httpClient.get(
      server.LIVEORDER_URL + `/pending`
    );
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const getLiveOrdersASC = () => {
  try {
    return httpClient.get(
      server.LIVEORDER_URL + `/pending-asc`
    );
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const updateLiveOrderStatus = data => {
    try {
      return httpClient.put(
        server.LIVEORDER_URL + `/status`, data
      );
    } catch (error) {
      console.log(error.message);
      return null;
    }
  };

  export const updateLiveOrderEditDetail = data => {
    try {
      return httpClient.put(
        server.LIVEORDER_URL + `/order-edit`, data
      );
    } catch (error) {
      console.log(error.message);
      return null;
    }
  };

export const deleteLiveOrder = id => {
  return httpClient.delete(server.LIVEORDER_URL + `/id/${id}`);
};

export const getLiveOrderByOrderId = id => {
  return httpClient.get(server.LIVEORDER_URL + `/id/${id}`);
};